<h2 mat-dialog-title>Quick Reply Messages</h2>

<mat-dialog-content>
  <form [formGroup]="settingsForm">
    <div formArrayName="predefinedMessages">
      <div class="header">
        <div class="add-button-container">
          <button class="add-icon" (click)="addMessage()" mat-mini-fab color="primary">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <mat-form-field appearance="fill" class="full-width search-field">
          <mat-label>Search messages</mat-label>
          <input matInput (keyup)="applyFilter($event)" />
        </mat-form-field>
      </div>
      <div class="messages-list">
        <mat-accordion class="example-headers-align" multi>
          <mat-expansion-panel *ngFor="let message of filteredMessages; let i = index" [formGroupName]="message.index">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <button
                  class="select-message-button"
                  mat-mini-fab
                  color="primary"
                  matTooltip="Use message"
                  (click)="selectMessage(message.index); $event.stopPropagation()">
                  <mat-icon>touch_app</mat-icon>
                </button>
              </mat-panel-title>
              <mat-panel-description> {{ getMessageDisplayName(message) }} </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Name</mat-label>
              <input matInput [formControlName]="'title'" />
            </mat-form-field>
            <div class="tag-wrapper">
              <mat-icon fontIcon="tag"> </mat-icon>
              <mat-chip-set>
                <mat-chip *ngFor="let chip of chips" (click)="insertChipTag(chip.value, i)">
                  {{ chip.display }}
                </mat-chip>
              </mat-chip-set>
            </div>
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Message</mat-label>
              <textarea
                #messageTextarea
                matInput
                cdkTextareaAutosize
                cdkAutosizeMinRows="2"
                [formControlName]="'text'"></textarea>
              <mat-error *ngIf="predefinedMessages.at(message.index).get('text').hasError('required')">
                Message is required
              </mat-error>
            </mat-form-field>
            <mat-action-row>
              <button
                mat-mini-fab
                color="warn"
                matTooltip="Delete message"
                class="delete-button"
                (click)="removeMessage(message.index)"
                [disabled]="predefinedMessages.length === 1">
                <mat-icon>delete</mat-icon>
              </button>
            </mat-action-row>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="no-messages" *ngIf="filteredMessages.length === 0">
        No messages found. Try a different search or add a new message.
      </div>
    </div>
  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()" [disabled]="isLoading">Cancel</button>
  <button mat-raised-button color="primary" (click)="saveSettings()" [disabled]="settingsForm.invalid || isLoading">
    Save
  </button>
</mat-dialog-actions>
<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
