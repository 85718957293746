import {Injectable} from "@angular/core";
import {ConnectionService} from "./connection.service";
import {HubspotIntegration} from "../../../../server/src/db/classes/notification-integration.type";
import {OperationConfigType} from "../../../../server/src/db/classes/operation-config.type";

@Injectable({
  providedIn: "root"
})
export class HubspotIntegrationService {
  constructor(private connectionService: ConnectionService) {}

  async manageAccessToken(id: string, type: OperationConfigType, accessToken: string): Promise<HubspotIntegration> {
    return await this.connectionService.post("/operation-config/integration/hubspot/manageAccessToken", {
      id,
      type,
      accessToken
    });
  }
}
