import {isEqual} from "lodash";
import ArrayHelpers from "./array-helpers";

export default class ObjectHelpers {
  static Compare(oldObj: any, newObj: any, parentKey = ""): any {
    if (isEqual(oldObj, newObj)) return {}; // Return an empty object if identical

    if (Array.isArray(newObj)) {
      return this.compareArrays(oldObj, newObj, parentKey);
    }

    if (this.isObject(newObj)) {
      return this.compareObjects(oldObj, newObj, parentKey);
    }

    // Primitives
    return {[parentKey]: newObj};
  }

  private static compareArrays(oldArray: any[], newArray: any[], parentKey: string) {
    if (!Array.isArray(oldArray) || newArray.length !== oldArray.length) {
      return {[parentKey]: newArray};
    }

    const isArrayOfObjects = newArray.every((elem, idx) => this.isObject(elem) && this.isObject(oldArray[idx]));

    if (!isArrayOfObjects) {
      return !isEqual(oldArray, newArray) ? {[parentKey]: newArray} : {};
    }

    // Arrays of objects: compare each element
    let updatedFields = {};

    newArray.forEach((newElem, idx) => {
      const nestedChanges = this.Compare(oldArray[idx], newElem, `${parentKey}[${idx}]`);
      if (nestedChanges && Object.keys(nestedChanges).length) {
        updatedFields = {...updatedFields, ...nestedChanges};
      }
    });

    return updatedFields;
  }

  private static compareObjects(oldObj: any, newObj: any, parentKey: string) {
    let updatedFields = {};

    const allKeys = new Set([...Object.keys(newObj), ...Object.keys(oldObj || {})]);

    allKeys.forEach(key => {
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
      const oldVal = oldObj?.[key];
      const newVal = newObj[key];

      if (key in newObj) {
        const nestedChanges = this.Compare(oldVal, newVal, fullKey);
        if (nestedChanges && Object.keys(nestedChanges).length) {
          updatedFields = {...updatedFields, ...nestedChanges};
        }
      } else {
        updatedFields[fullKey] = undefined; // Field was removed
      }
    });

    return updatedFields;
  }

  private static isObject(value: any): boolean {
    return value && typeof value === "object" && !Array.isArray(value);
  }

  static sortNodesAndChildren = nodes => {
    nodes.sort(ArrayHelpers.SortAlphabetically());
    nodes.forEach(node => {
      if (node.children) {
        this.sortNodesAndChildren(node.children);
      }
    });
  };
}
