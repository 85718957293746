<h2 mat-dialog-title>Request a Demo</h2>
<form [formGroup]="demoForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Full Name</mat-label>
      <input matInput formControlName="fullName" />
      <mat-error *ngIf="demoForm.get('fullName')?.hasError('required')"> Full name is required. </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Email</mat-label>
      <input matInput type="email" formControlName="email" />
      <mat-error *ngIf="demoForm.get('email')?.hasError('required')"> Email is required. </mat-error>
      <mat-error *ngIf="demoForm.get('email')?.hasError('pattern')"> Please enter a valid email address. </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Phone</mat-label>
      <input matInput type="tel" formControlName="phone" />
      <mat-error *ngIf="demoForm.get('phone')?.hasError('required')"> Phone number is required. </mat-error>
      <mat-error
        *ngIf="!demoForm.get('phone')?.hasError('required') && demoForm.get('phone')?.hasError('invalidPhone')">
        Please enter a valid phone number.
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <div mat-dialog-actions>
    <button mat-button type="button" mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="submitting">Submit</button>
  </div>
</form>
