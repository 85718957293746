import {Component, Input} from "@angular/core";
import {OperationConfigType} from "../../../../../../../server/src/db/classes/operation-config.type";
import {DataService} from "src/app/Services/data.service";
import {IntegrationService} from "src/app/Services/integration.service";
import {
  NotificationIntegrationType,
  ZapierIntegration
} from "../../../../../../../server/src/db/classes/notification-integration.type";
import {SourceConfigDialogData} from "src/app/dialogs/source-config-dialog/source-config-dialog.component";

@Component({
  selector: "app-zapier-integration-config",
  templateUrl: "./zapier-integration-config.component.html",
  styleUrl: "./zapier-integration-config.component.scss"
})
export class ZapierIntegrationConfigComponent {
  @Input() integration: ZapierIntegration;
  @Input() sourceConfig: SourceConfigDialogData;

  public isConfirmDelete: boolean = false;
  public isLodaing = false;
  constructor(
    private dataService: DataService,
    private integrationService: IntegrationService
  ) {}

  public get operationConfigId(): string {
    switch (this.sourceConfig.type) {
      case OperationConfigType.Location: {
        return this.dataService.allSourcesHashedById$.value[this.sourceConfig.sourceId].locationConfig$;
      }
      case OperationConfigType.Business: {
        return this.sourceConfig.businessId;
      }
      default:
        throw new Error("Unsupported operation type");
    }
  }

  public async toggleEnable(newStatus: boolean): Promise<void> {
    await this.integrationService.toggleEnable(
      this.operationConfigId,
      NotificationIntegrationType.Zapier,
      this.sourceConfig.type,
      newStatus
    );

    this.integration.enabled = newStatus;
  }

  public confirmDelete(): void {
    this.isConfirmDelete = true;
  }

  public cancelDelete(): void {
    this.isConfirmDelete = false;
  }

  public async deleteAcessToken(): Promise<void> {
    const result = await this.integrationService.removeAccessToken(
      this.operationConfigId,
      NotificationIntegrationType.Zapier
    );

    if (result) {
      this.integration.apiKey = null;

      this.toggleEnable(false);
    } else {
      this.cancelDelete();
    }
  }
}
