import {Component, ElementRef, HostListener, Input, ViewChild} from "@angular/core";
import ForceGraph from "force-graph";

@Component({
  selector: "app-animation",
  standalone: true,
  imports: [],
  templateUrl: "./animation.component.html",
  styleUrl: "./animation.component.scss"
})
export class AnimationComponent {
  @Input() text: string;
  @Input() number: number;
  @Input() numberPostfix: string;
  @Input() timeMs: number;

  @ViewChild("graph", {static: true}) private graph: ElementRef;

  constructor(private elRef: ElementRef) {}

  ngAfterViewInit() {
    this.createGraph();
  }

  nodes = [
    {
      src: "assets/integrations-animation/yelp.webp",
      fy: -50,
      fx: -20,
      size: 12,
      text: "Yelp"
    },
    {
      src: "assets/integrations-animation/google.png",
      fy: -50,
      fx: 20,
      size: 12,
      id: "Google",
      text: "Google (in dev)"
    },
    {
      src: "assets/logo/logo-cropped.svg",
      fy: -25,
      fx: 0,
      size: 15,
      text: "NZLeads"
    },
    {
      id: "integrations",
      src: "assets/integrations-animation/integration.png",
      fy: 0,
      fx: 0
    },
    {
      src: "assets/integrations-animation/zapier.ico",
      text: "Zapier"
    },
    {
      src: "assets/integrations-animation/pipedrive.webp",
      text: "PipeDrive"
    },
    {
      src: "assets/integrations-animation/slack.webp",
      text: "Slack"
    },

    {
      src: "assets/integrations-animation/whatsapp.webp",
      text: "Whatsapp"
    },
    {
      id: "Twilio",
      src: "assets/integrations-animation/twilio-icon.svg",
      text: "SMS"
    },
    {src: "assets/integrations-animation/hubspot.svg", text: "HubSpot"},
    {src: "assets/integrations-animation/workiz.png", text: "Workiz"},
    {src: "assets/integrations-animation/highlevel.jpeg", text: "GoHighLevel"}
    // {
    //   src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Salesforce.com_logo.svg/800px-Salesforce.com_logo.svg.png',
    //   text: 'Salesforce',
    // },
    // {
    //   src: 'https://play-lh.googleusercontent.com/4lc5KTwIKPqbNSk0IvhbFfZ-alRCiOGXp9i5JoS3BcAnE4ntcTTmS3Pzn21W7LDe5U4=w240-h480-rw',
    //   text: 'Copper',
    // },
    // {
    //   src: 'https://www.svgrepo.com/show/354088/monday-icon.svg',
    //   text: 'Monday',
    // },
    // {
    //   src: 'https://play-lh.googleusercontent.com/Zgz0aJ8V-d3E_5MMc-D_Ptz2LrcFTFSLqQoYH-vjzmKr7N12Omo8O-Xoc8-LyMO-1w=w240-h480-rw',
    //   text: 'Zendesk',
    // },
    // { src: 'https://www.svgrepo.com/show/354603/zoho.svg', text: 'Zoho' },
  ].map((node: any, index) => {
    node.img = new Image();
    node.img.src = `${node.src}`;
    node.id = node.id || node.text;
    return node;
  });

  fontSize = 3;

  gData = {
    nodes: this.nodes,
    links: [
      {source: "Yelp", target: "NZLeads", curvature: 0, speed: 0.01, particles: 7},
      {source: "NZLeads", target: "Yelp", curvature: 0, speed: 0.01, particles: 3},

      {source: "Google", target: "NZLeads", curvature: 0, speed: 0.01},
      {source: "NZLeads", target: "Google", curvature: 0, speed: 0.01},

      {source: "NZLeads", target: "integrations", curvature: 0, speed: 0.01, particles: 5},

      {source: "integrations", target: "Slack", curvature: 0, speed: 0.02},
      {source: "integrations", target: "Whatsapp", curvature: 0, speed: 0.02},
      {source: "integrations", target: "Twilio", curvature: 0, speed: 0.015},
      {source: "integrations", target: "PipeDrive", curvature: 0, speed: 0.01},
      {source: "integrations", target: "HubSpot", curvature: 0, speed: 0.01},
      {source: "integrations", target: "Zapier", curvature: 0, speed: 0.015},
      {source: "integrations", target: "GoHighLevel", curvature: 0, speed: 0.015},

      {source: "integrations", target: "Workiz", curvature: 0, speed: 0.015}
      // {
      //   source: 'integrations',
      //   target: 'Salesforce',
      //   curvature: 0,
      //   speed: 0.004,
      // },
      // { source: 'integrations', target: 'Copper', curvature: 0, speed: 0.005 },
      // { source: 'integrations', target: 'Monday', curvature: 0, speed: 0.006 },
      // { source: 'integrations', target: 'Zendesk', curvature: 0, speed: 0.007 },

      // { source: 'integrations', target: 'Zoho', curvature: 0, speed: 0.009 },
    ]
  };
  Graph;

  updateGrapthSize() {
    // Get the graph dimensions
    const graphWidth = Math.min(500, this.graph.nativeElement.offsetWidth);
    const graphHeight = graphWidth * 1.25; // this.graph.nativeElement.offsetHeight;

    // Set the graph size
    this.Graph.width(graphWidth);
    this.Graph.height(graphHeight);
    return {graphWidth, graphHeight};
  }

  fitGraph(t) {
    this.updateGrapthSize(); //fix for flickering
    const graphSize = this.updateGrapthSize();

    // Initialize the bounding box variables
    let minX = Infinity,
      maxX = -Infinity,
      minY = Infinity,
      maxY = -Infinity;

    // Calculate the bounding box including text width
    this.gData.nodes.forEach((node: any) => {
      const size = node.size || 10; // Image size

      // Create a temporary canvas context to measure the text width
      const tempCanvas = document.createElement("canvas");
      const ctx = tempCanvas.getContext("2d");
      ctx.font = `${this.fontSize}px Sans-Serif`;
      const textWidth = ctx.measureText(node.text || "").width;

      // Calculate node boundaries considering image and text width
      const nodeLeft = node.x - Math.max(size / 2, textWidth / 2) - this.fontSize * 0.2;
      const nodeRight = node.x + Math.max(size / 2, textWidth / 2) + this.fontSize * 0.2;
      const nodeTop = node.y - size / 2;
      const nodeBottom = node.y + size / 2 + this.fontSize + 2; // Adjusted padding for bottom

      // Update bounding box dimensions
      minX = Math.min(minX, nodeLeft);
      maxX = Math.max(maxX, nodeRight);
      minY = Math.min(minY, nodeTop);
      maxY = Math.max(maxY, nodeBottom);
    });

    // Calculate the bounding box width and height
    const bboxWidth = maxX - minX;
    const bboxHeight = maxY - minY;

    // Padding to ensure images and text are fully visible
    const padding = 0; // Use zero padding for a snug fit

    // Add padding to the bounding box
    const paddedWidth = bboxWidth + 2 * padding;
    const paddedHeight = bboxHeight + 2 * padding;

    // Determine the scale factor to fit the graph within the canvas
    const scaleFactor = Math.min(graphSize.graphWidth / paddedWidth, graphSize.graphHeight / paddedHeight);

    // Apply zoom and center the graph within the canvas
    this.Graph.zoom(scaleFactor, t);
    // if(t===300){
    //   setTimeout(()=>{
    //     fitGraph(0);
    //   },400)
    // }
    this.Graph.centerAt((minX + maxX) / 2, (minY + maxY) / 2, 0);
  }

  createGraph() {
    this.Graph = ForceGraph()(this.graph.nativeElement)
      .nodeCanvasObject((node: any, ctx) => {
        const size = (node.size = node.size || 10);
        const imageHeight = (size / node.img.width) * node.img.height;
        ctx.drawImage(node.img, node.x - size / 2, node.y - imageHeight / 2, size, imageHeight);

        // node.img.style.paddingBottom = '10px';

        if (!node.text) {
          return;
        }
        const label = node.text;
        ctx.font = `${this.fontSize}px Sans-Serif`;
        const textWidth = ctx.measureText(label).width;
        const bckgDimensions: any = [textWidth, this.fontSize].map(n => n + this.fontSize * 0.2); // some padding

        ctx.fillStyle = "rgba(255, 255,255, 0.2)";
        const texty = node.y + node.size / 2 + 3;
        // @ts-ignore
        ctx.fillRect(node.x - bckgDimensions[0] / 2, texty - bckgDimensions[1] / 2, ...bckgDimensions);

        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "black";
        ctx.fillText(label, node.x, texty);
      })
      .linkDirectionalParticles((d: any) => {
        return d.particles || 2;
      })
      .linkCurvature("curvature")

      .enableZoomInteraction(false)
      .enableNodeDrag(false)
      .enablePanInteraction(false)
      .enablePointerInteraction(false)
      // .onNodeHover((node) => {
      //   console.log(node);
      // })

      .warmupTicks(100)
      .cooldownTicks(0)

      .linkDirectionalParticleSpeed((d: any) => d.speed || 0.01)
      .nodePointerAreaPaint((node, color, ctx) => {
        const size = 12;
        ctx.fillStyle = color;
        ctx.fillRect(node.x - size / 2, node.y - size / 2, size, size); // draw square as pointer trap
      })
      .graphData(this.gData);

    // fit to canvas when engine stops
    this.Graph.onEngineStop(() => {
      this.fitGraph(300);
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.fitGraph(0);
  }
}
