import {Injectable} from "@angular/core";
import {ConnectionService} from "./connection.service";
import {PipeDriveIntegration} from "../../../../server/src/db/classes/notification-integration.type";
import {OperationConfigType} from "../../../../server/src/db/classes/operation-config.type";

@Injectable({
  providedIn: "root"
})
export class PipedriveIntegrationService {
  constructor(private connectionService: ConnectionService) {}

  async manageAccessToken(id: string, type: OperationConfigType, accessToken: string): Promise<PipeDriveIntegration> {
    return await this.connectionService.post("/operation-config/integration/pipedrive/manageAccessToken", {
      id,
      type,
      accessToken
    });
  }
}
