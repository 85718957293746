import {NgModule} from "@angular/core";
import {provideRouter, RouterModule, Routes, withRouterConfig} from "@angular/router";
import {CodeConfirmComponent} from "./components/main-login/code-confirm/code-confirm.component";
import {MainLoginComponent} from "./components/main-login/main-login.component";
import {LoginGuard} from "./guards/login.guard";
import {MainGuard} from "./guards/main.guard";
import {AcceptedComponent} from "./Pages/accepted/accepted.component";
import {LoginComponent} from "./components/main-login/login/login.component";
import {MainComponent} from "./components/main/main.component";
import {LeadsComponent} from "./Pages/leads/leads.component";
import {SourcesComponent} from "./Pages/sources/sources.component";
import {ReportsComponent} from "./Pages/reports/reports.component";
import {SignUpComponent} from "./components/main-login/signup/sign-up.component";
import {SuperAdminComponent} from "./Pages/superAdmin/superAdmin.component";
import {SourceTokenComponent} from "./Pages/source-token/source-token.component";
import {ModalGuard} from "./guards/modal.guard";
import {HomeComponent} from "./marketing/pages/home/home.component";
import {ReportsGraphComponent} from "./Pages/graph/graph-reports.component";
import {PrivacyComponent} from "./compliance/privacy/privacy.component";
import {TermOfServiceComponent} from "./compliance/term-of-service/term-of-service.component";
import {BlogComponent} from "./marketing/pages/blog/blog.component";
import {ConfirmRevokeTokenComponent} from "./Pages/confirm-revoke-token/confirm-revoke-token.component";
import {CheckoutCompletedComponent} from "./Pages/sources/checkout-completed.component";
import {ActivityLogComponent} from "./Pages/activityLog/activity-log.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    canActivate: [LoginGuard],
    children: [
      {
        path: "blog",
        component: BlogComponent,
        children: []
      }
    ]
  },
  {
    path: "login",
    component: MainLoginComponent,
    canActivate: [LoginGuard],
    children: [
      {
        path: "",
        component: LoginComponent,
        children: []
      },
      {
        path: "signup",
        component: SignUpComponent,
        children: []
      },
      {
        path: "forgot",
        component: SignUpComponent,
        children: []
      },
      {
        path: "confirm-code",
        component: CodeConfirmComponent,
        children: []
      },
      {path: "**", redirectTo: ""}
    ]
  },
  {
    path: "app",
    component: MainComponent,
    canActivate: [MainGuard],
    children: [
      {
        path: "tokens",
        component: SourceTokenComponent,
        children: []
      },
      {
        path: "sources",
        component: SourcesComponent,
        children: [
          {
            path: "s", //stripe indication
            component: CheckoutCompletedComponent,
            children: []
          }
        ]
      },
      {
        path: "activity-log",
        component: ActivityLogComponent,
        children: []
      },
      {
        path: "leads",
        canDeactivate: [ModalGuard],
        component: LeadsComponent,
        children: []
      },
      {
        path: "reports",
        component: ReportsComponent,
        children: []
      },
      {
        path: "graph-reports",
        component: ReportsGraphComponent,
        children: []
      },
      {
        path: "superAdmin",
        component: SuperAdminComponent,
        children: []
      },
      {path: "**", redirectTo: "leads"}
    ]
  },
  {
    path: "accepted_finished",
    component: AcceptedComponent,
    children: []
  },
  {
    path: "accepted_slack_finished",
    component: AcceptedComponent,
    children: []
  },
  {
    path: "accepted_failed",
    component: AcceptedComponent,
    children: []
  },
  {
    path: "confirm_revoke_token",
    component: ConfirmRevokeTokenComponent,
    children: []
  },
  {
    path: "confirm_revoke_token_failed",
    component: ConfirmRevokeTokenComponent,
    children: []
  },
  {
    path: "privacy",
    component: PrivacyComponent,
    children: []
  },
  {
    path: "tos",
    component: TermOfServiceComponent,
    children: []
  },
  {path: "**", redirectTo: ""}
];

provideRouter(
  routes,
  withRouterConfig({
    canceledNavigationResolution: "computed"
  })
);

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
