import {Component, ElementRef, Inject, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {LeadsService} from "../../Services/leads.service";
import {BotMessage, Lead} from "../../../../../server/src/db/classes/lead";
import {Source} from "../../../../../server/src/db/classes/source";
import {SessionService} from "../../Services/session.service";
import {DateTime} from "luxon";
import {LeadsComponent} from "../../Pages/leads/leads.component";
import {UntilDestroy} from "@ngneat/until-destroy";
import {UserSetting} from "../../../../../server/src/db/classes/user";
import {UserSettingsDialogComponent} from "../user-settings-dialog/user-settings-dialog.component";

import {LeadScheduledMessage, LeadStatus} from "../../../../../server/src/db/classes/lead.types";
import {UserSendScheduledMessageDialogComponent} from "../user-send-scheduled-message-dialog/user-send-scheduled-message-dialog.component";
import {ScheduledMessagesDialogComponent} from "../scheduled-messages-follow-ups-dialog/scheduled-messages-dialog.component";

@UntilDestroy()
@Component({
  selector: "lead-messages-dialog",
  templateUrl: "./lead-messages-dialog.component.html",
  styleUrls: ["./lead-messages-dialog.component.scss"]
})
export class LeadMessagesDialogComponent {
  textToSend = "";
  public userSettings: UserSetting | null;

  @ViewChild("scrollMe") private myScrollContainer: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      lead: Lead;
      location: Source;
      isMemo: boolean;
      leadsComponent: LeadsComponent;
    },
    public dialogRef: MatDialogRef<LeadMessagesDialogComponent>,
    public sessionService: SessionService,
    private leadsService: LeadsService,
    private matDialog: MatDialog
  ) {
    this.markAsRead();
  }

  ngAfterViewInit() {
    this.scrollToBottom();
  }

  async cancelScheduledMessage() {
    try {
      await this.leadsService.removeScheduledMessage(this.data.lead);
      console.log("Scheduled message cancelled successfully");
    } catch (error) {
      console.error("Error cancelling scheduled message:", error);
    }
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      setTimeout(() => {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      });
    } catch (err) {}
  }

  sendLeadMessage = async () => {
    const originalText = this.textToSend;
    this.textToSend = "";
    this.data.lead.waitingForBizSentMessageToAppear = true;
    try {
      await this.leadsService.sendLeadMessage(this.data.lead._id, originalText);
    } catch (e) {
      this.textToSend = originalText;
      this.data.lead.waitingForBizSentMessageToAppear = false;
      return;
    }

    // this.data.lead.messages.push({
    //   time_created: DateTime.now(),
    //   user_display_name: "in progress...",
    //   event_type: "TEXT",
    //   user_type: "BIZ",
    //   event_content: {text: this.textToSend}
    // });

    this.data.lead.waitingForBizSentMessageToAppear = true;
    this.textToSend = "";
    this.scrollToBottom();
  };

  openInYelp() {
    window.open(this.data.lead.conversationLink, "_blank");
  }

  addMemo = async () => {
    this.data.lead.memos = this.data.lead.memos || [];
    this.data.lead.memos.push({
      time_created: DateTime.now(),
      user_display_name: this.sessionService.session.email,
      event_type: "TEXT",
      user_type: "BIZ",
      event_content: {text: this.textToSend}
    });
    this.data.lead.memos = [...this.data.lead.memos];
    await this.leadsService.addMemo(this.data.lead._id, this.textToSend);
    this.data.leadsComponent.ignoreNextPush++;
    this.textToSend = "";
    this.scrollToBottom();
  };

  async markAsRead() {
    const currentUserId = this.sessionService.session.id;
    if (this.sessionService.prevSessionSuperAdmin) {
      this.data.lead.readBy.push(this.sessionService.session.id);
    } else if (this.data.lead?.readBy?.includes && !this.data.lead.readBy?.includes(currentUserId)) {
      await this.leadsService.markRead(this.data.lead);
      this.data.lead.readBy.push(this.sessionService.session.id);
    }
  }

  async openUserSettingDialog(): Promise<void> {
    const dialogRef = this.openDialog();
    this.handleDialogClosure(dialogRef);
  }

  async saveUserSetting(): Promise<void> {
    const dialogRef = this.openDialog({
      data: {
        predefinedMessages: this.textToSend
      }
    });
    this.handleDialogClosure(dialogRef);
  }

  private openDialog(config: MatDialogConfig = {}): MatDialogRef<UserSettingsDialogComponent> {
    const defaultConfig: MatDialogConfig = {
      maxWidth: "1000px",
      width: "100%",
      closeOnNavigation: false
    };

    return this.matDialog.open(UserSettingsDialogComponent, {...defaultConfig, ...config});
  }

  private handleDialogClosure(dialogRef: MatDialogRef<UserSettingsDialogComponent>): void {
    dialogRef.afterClosed().subscribe(message => {
      if (typeof message === "string") {
        if (!this.data.isMemo && this.data.lead.waitingForBizSentMessageToAppear) {
          return;
        }

        const messageWithName = message
          .split("#customer_display_name#")
          .join(this.data.lead.messages[0].user_display_name);
        this.textToSend = messageWithName;
      }
    });
  }

  public async openScheduledMessageDialog(): Promise<void> {
    const dialogRef = this.getDialogRefScheduledMessageDialog({
      data: {
        lead: this.data.lead
      }
    });
    this.handleScheduledMessageDialogClosure(dialogRef);
  }

  public getDialogRefScheduledMessageDialog(
    config: MatDialogConfig = {}
  ): MatDialogRef<ScheduledMessagesDialogComponent> {
    const defaultConfig: MatDialogConfig = {
      maxWidth: "1000px",
      height: "80%",
      width: "100%",
      closeOnNavigation: false
    };

    return this.matDialog.open(ScheduledMessagesDialogComponent, {...defaultConfig, ...config});
  }

  private handleScheduledMessageDialogClosure(dialogRef: MatDialogRef<ScheduledMessagesDialogComponent>): void {
    dialogRef.afterClosed().subscribe((result: LeadScheduledMessage | null) => {
      if (result) {
        this.data.lead.scheduledMessage = result;
        this.data.lead.status = LeadStatus.ScheduledMessage;
      }
    });
  }

  public openSendScheduledMessage(): void {
    const dialogRef = this.matDialog.open(UserSendScheduledMessageDialogComponent, {
      maxWidth: "1000px",
      width: "100%",
      closeOnNavigation: false,
      data: {
        lead: this.data.lead,
        message: this.textToSend
      }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.textToSend = "";
      }
    });
  }

  async fetchNewMessages(): Promise<BotMessage[]> {
    return await this.leadsService.fetchNewMessages(this.data.lead);
  }

  async removeMessage(index: number): Promise<void> {
    const removedMessage = this.data.lead.memos[index];
    try {
      this.data.lead.memos.splice(index, 1);
      await this.leadsService.removeMemoMessage(this.data.lead._id, index);
    } catch (e) {
      console.error("Failed to remove message:", e);
      this.data.lead.memos.splice(index, 0, removedMessage);
    }
  }
}
