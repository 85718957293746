import {Component, OnInit, ViewChildren, QueryList, ElementRef, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UserService} from "src/app/Services/user.service";
import {UserSettingScheduledMessage} from "../../../../../server/src/db/classes/user.interfaces";
import {FormBuilder, FormGroup, FormArray} from "@angular/forms";
import {MatExpansionPanel} from "@angular/material/expansion";
import {
  YelpConfigFollowUp,
  YelpConfigFollowUpPredefinedMessages,
  YelpConfigFollowUpPredefinedMessagesType
} from "../../../../../server/src/db/classes-helpers/yelpConfig";
import {Lead} from "../../../../../server/src/db/classes/lead";
import {LeadsService} from "src/app/Services/leads.service";

@Component({
  selector: "app-user-scheduled-messages-dialog",
  templateUrl: "./scheduled-messages-dialog.component.html",
  styleUrls: ["./scheduled-messages-dialog.component.scss"]
})
export class ScheduledMessagesDialogComponent implements OnInit {
  @ViewChildren(MatExpansionPanel) panels: QueryList<MatExpansionPanel>;
  @ViewChildren("configTextarea") configTextareas: ElementRef[];
  public settingsForm: FormGroup;
  public isLoading = false;
  public filteredMessages: {index: number; title: string; config: YelpConfigFollowUp}[] = [];
  private currentFilter = "";

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      lead: Lead;
    },
    public dialogRef: MatDialogRef<ScheduledMessagesDialogComponent>,
    private userService: UserService,
    private fb: FormBuilder,
    private leadService: LeadsService
  ) {
    this.settingsForm = this.fb.group({
      scheduledMessages: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.loadUserSettings();
  }

  get scheduledMessages(): FormArray {
    return this.settingsForm.get("scheduledMessages") as FormArray;
  }

  public onCancel(): void {
    this.dialogRef.close(null);
  }

  public async saveSettings(): Promise<void> {
    if (this.settingsForm.valid) {
      this.isLoading = true;
      try {
        const scheduledMessages = this.scheduledMessages.value;
        const updatedUser = await this.userService.updateUserSettings(null, scheduledMessages);
        this.dialogRef.close(null);
      } catch (error) {
        console.error("Error updating user settings:", error);
      } finally {
        this.isLoading = false;
      }
    }
  }

  private async loadUserSettings(): Promise<void> {
    this.isLoading = true;
    try {
      const settings = await this.userService.getUserSettings();
      if (settings && settings.scheduledMessages) {
        settings.scheduledMessages.forEach(message => {
          const messageGroup = this.createMessageFormGroup(message);
          this.scheduledMessages.push(messageGroup);
          this.setupTitleListener(messageGroup, this.scheduledMessages.length - 1);
        });
      }
      this.updateFilteredMessages();
    } catch (error) {
      console.error("Error loading user settings:", error);
    } finally {
      this.isLoading = false;
    }
  }

  private createMessageFormGroup(message?: UserSettingScheduledMessage): FormGroup {
    return this.fb.group({
      title: [message?.title || ""],
      config: [message?.config || this.createDefaultConfig()]
    });
  }

  private createDefaultConfig(): YelpConfigFollowUp {
    return {
      predefinedMessages: [
        {
          text: "",
          minutes: 60,
          displayValue: 60,
          type: YelpConfigFollowUpPredefinedMessagesType.Minutes
        }
      ]
    };
  }

  private setupTitleListener(messageGroup: FormGroup, index: number): void {
    messageGroup.valueChanges.subscribe(() => {
      this.updateMessageDisplayName(index);
    });
  }

  private updateMessageDisplayName(index: number): void {
    const messageGroup = this.scheduledMessages.at(index) as FormGroup;
    const title = messageGroup.get("title").value;
    const config = messageGroup.get("config").value;
    const newDisplayName = this.getMessageDisplayName({title, config});

    if (this.filteredMessages[index] && this.filteredMessages[index].title !== newDisplayName) {
      this.filteredMessages[index].title = newDisplayName;
    }
  }

  public addMessage(): void {
    const newMessage = this.createMessageFormGroup();
    this.scheduledMessages.insert(0, newMessage);
    this.setupTitleListener(newMessage, 0);
    this.updateFilteredMessages();
    this.expandNewMessage();
  }

  private expandNewMessage(): void {
    setTimeout(() => {
      this.panels.first.open();
    });
  }

  public removeMessage(index: number): void {
    this.scheduledMessages.removeAt(index);
    this.updateFilteredMessages();
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    if (filterValue !== this.currentFilter) {
      this.currentFilter = filterValue;
      this.updateFilteredMessages(filterValue);
    }
  }

  private updateFilteredMessages(filterValue: string = ""): void {
    this.filteredMessages = this.scheduledMessages.controls
      .map((control, index) => ({
        index,
        title: this.getMessageDisplayName(control.value),
        config: control.get("config").value
      }))
      .filter(
        message =>
          message.title.toLowerCase().includes(filterValue) ||
          JSON.stringify(message.config).toLowerCase().includes(filterValue)
      );
  }

  public getMessageDisplayName(message: {title: string; config: YelpConfigFollowUp}): string {
    if (message.title) {
      return message.title;
    }
    const firstMessage = message.config.predefinedMessages[0];
    return firstMessage.text.slice(0, 30) + (firstMessage.text.length > 30 ? "..." : "");
  }

  public async selectMessage(index: number): Promise<void> {
    const selectedScheduledMessage = this.scheduledMessages.at(index);

    if (!selectedScheduledMessage.valid) {
      return;
    }

    const selectedMessageConfig = selectedScheduledMessage.value.config;

    const isValid = selectedMessageConfig.predefinedMessages.every(
      (message: YelpConfigFollowUpPredefinedMessages) => message.text.trim().length >= 2
    );

    if (!isValid) {
      return;
    }

    selectedMessageConfig.createdAt = new Date();

    await this.leadService.setScheduledMessage(this.data.lead, selectedMessageConfig);
    this.dialogRef.close(selectedMessageConfig);
  }
}
