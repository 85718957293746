import {Component} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {Sort} from "@angular/material/sort";
import {DateTime} from "luxon";
import {debounceTime} from "rxjs";
import {YelpReportService} from "../../Services/yelp-report.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {BotMessage} from "../../../../../server/src/db/classes/lead";
import {DataService} from "../../Services/data.service";
import {CalculateActiveTime} from "../../../../../server/src/helpers/time-helper";
import {SessionService} from "../../Services/session.service";
import {LeadsService} from "../../Services/leads.service";

@UntilDestroy()
@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"]
})
export class ReportsComponent {
  sourceReportsRawData = [];
  originalSourceReports = [];
  sortedSourceReports = [];
  ready = false;
  currentSort: any = {active: "totalLeads", direction: "desc"};

  columns = [
    {field: "name", title: "Source Name"},
    {field: "totalLeads", title: "Leads"},
    {field: "customerAnswered", title: "Customer Answered"},
    {field: "found", title: "Phones"},
    {field: "followUp", title: "Follow-ups sent"}
    // {field: "handled", title: "Total Incoming/Outgoing Messages"}
    // {field: 'activeDays', title: "Active Days", superAdmin: true},
    // {field: 'price', title: "Price", superAdmin: true}
  ];

  totalRow = {
    price: 0
  };

  columnsFields = [];

  filterStatus = [];

  range = new FormGroup({
    start: new FormControl(DateTime.now().startOf("month").toJSDate()),
    end: new FormControl(DateTime.now().endOf("month").toJSDate())
  });

  constructor(
    private yelpReportService: YelpReportService,
    private dataService: DataService,
    public leadsService: LeadsService,
    public sessionService: SessionService
  ) {
    this.columns = this.columns.filter(c => {
      return !c["superAdmin"] || this.sessionService.session.role === "superAdmin";
    });

    this.columnsFields = this.columns.map(x => x.field);

    dataService.currentSourcesList$.pipe(untilDestroyed(this)).subscribe(sources => {
      if (sources !== null) {
        this.refresh();
      }
    });

    this.range.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(event => {
      if (event.start && event.end) {
        this.refresh();
      }
    });
  }

  filterStatusChanged = async event => {
    this.filterStatus = event.value;
    await this.refresh(false);
  };

  private refresh = async (refreshData = true) => {
    const self = this;
    this.ready = false;
    this.totalRow.price = 0;
    // this.sourceReportsRawData = aa.a;
    const range = this.range.value;
    range.end = DateTime.fromJSDate(range.end).plus({day: 1});
    if (refreshData) {
      this.sourceReportsRawData = await this.yelpReportService.get(
        range,
        this.dataService.currentSourcesList$.value.map(s => s._id)
      );
    }
    this.originalSourceReports = this.sourceReportsRawData.map(source => {
      let totalLeads = 0;
      let sent = 0;
      let greeting = 0;
      let followUp = 0;
      let handled = 0;
      let found = 0;
      let customerAnswered = 0;
      let leadTimes = [];

      source.leads
        .filter(l => {
          return self.filterStatus.length === 0 || self.filterStatus.includes(l.status);
        })
        .forEach(lead => {
          totalLeads++;
          if (lead.phoneNumber) {
            found++;
          }
          if (lead.messages.length >= 2) {
            const answeredMessage = lead.messages.find((m: BotMessage, index) => {
              return m.user_type === "BIZ" && index !== 0;
            });
            if (answeredMessage) {
              leadTimes.push(
                DateTime.fromISO(answeredMessage.time_created)
                  .diff(DateTime.fromISO(lead.messages[0].time_created))
                  .as("seconds")
              );
            }
          }
          let firstBotMessage = true;

          const firstBizMessage = lead.messages.findIndex(m => m.user_type === "BIZ");
          const lastCustomerMessage = lead.messages.findLastIndex(m => m.user_type === "CONSUMER");
          if (lastCustomerMessage > firstBizMessage) {
            customerAnswered++;
          }

          lead.messages.forEach((message, idx) => {
            if (message.user_type === "BIZ") {
              if (firstBotMessage) {
                greeting++;
                firstBotMessage = false;
              } else {
                followUp++;
              }
              sent++;
            }
            handled++;
          });
        });
      leadTimes = leadTimes.filter(x => x < 5000);
      let leadTimesAverage = 0;
      if (leadTimes.length > 0) {
        leadTimesAverage = leadTimes?.reduce((a, b) => a + b) / leadTimes.length;
      }

      const activity = CalculateActiveTime(source.activity, new Date(range.start), new Date(range.end));
      const activeDays = activity.mili / 1000 / 60 / 60 / 24;

      const price = 125 * activity.percent;
      this.totalRow.price += price;

      leadTimesAverage = parseInt(leadTimesAverage.toString());
      return {
        _id: source._id,
        name: source.name,
        customerAnswered: `${customerAnswered} (${parseInt(String((customerAnswered / totalLeads) * 100)) || 0}%)`,
        found: `${found} (${parseInt(String((found / totalLeads) * 100)) || 0}%)`,
        greeting,
        followUp,
        sent,
        handled,
        leadTimesAverage,
        activeDays,
        price,
        totalLeads: totalLeads
      };
    });

    this.announceSortChange();
    this.ready = true;
  };

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  announceSortChange = (sort?: Sort) => {
    sort = sort || this.currentSort;
    this.currentSort = sort;
    const data = this.originalSourceReports.slice();
    this.sortedSourceReports = data.sort((a, b) => {
      return this.compare(parseInt(a[sort.active]), parseInt(b[sort.active]), sort.direction === "asc");
    });
  };
}
