<div mat-dialog-title>
  <span *ngIf="locationsNumber === 0"
    >Welcome!
    <br />
    <h5>Lets start by adding your Yelp Business</h5>
  </span>
  <span *ngIf="locationsNumber !== 0"> Add New Source Token </span>
</div>

<div mat-dialog-content [class.displayNone]="!link">
  <div class="form-container">
    <div *ngIf="allBusinessesList$.length > 1">
      <div class="small-header">Add New Source to Business:</div>
      <mat-form-field>
        <mat-label>Business:</mat-label>
        <mat-select [(value)]="selectedBusiness" (selectionChange)="selectedBusinessChanged($event)">
          <mat-select-trigger>
            <div class="flexRowParent">
              <mat-icon>location_city </mat-icon>&nbsp;{{ allBusinessesHashedById$[selectedBusiness]?.name }}
            </div>
          </mat-select-trigger>
          <mat-option
            *ngFor="let business of allBusinessesList$ | nzFunc: filterOutAllBusiness"
            value="{{ business._id }}">
            <div class="flexRowParent">
              <mat-icon>location_city</mat-icon>
              {{ business.name }}
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="buttons" style="justify-content: center">
      <a [href]="link" mat-button mat-flat-button (click)="dialogRef.close()" cdkFocusInitial>
        Add New Business from Yelp
      </a>
      <button mat-button (click)="contact()">Contact us for help</button>
    </div>
    <br />
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> More Options </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      Alternatively, to share with someone else, use this link to let them add their Yelp profile:

      <mat-form-field>
        <mat-label>Invite Link</mat-label>
        <textarea matInput onclick="this.select()" [ngModel]="link" [readonly]="true" cdkTextareaAutosize></textarea>
      </mat-form-field>

      <div class="buttons">
        <button mat-button [cdkCopyToClipboard]="link" cdkFocusInitial>Copy URL</button>
      </div>

      <div class="small-header" *ngIf="locationsNumber === 0" style="font-size: 12px">
        *If your business is already managed at {{ logoService.hostTextPrimary }} ask the current manager to add your
        account/email. or ask him to mark the source as "allow transfer" to completely pull it under your account<br />
      </div>
    </mat-expansion-panel>
  </div>
</div>

<div mat-dialog-actions *ngIf="locationsNumber > 0">
  <button mat-button mat-raised-button color="primary" (click)="dialogRef.close()" cdkFocusInitial>Close</button>
</div>

<div style="display: flex; justify-content: center; padding: 50px" *ngIf="!link">
  <div class="spinner-container">
    <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
  </div>
</div>
