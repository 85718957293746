import {Component, Inject} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from "@angular/material/dialog";
import {SourceDialogComponent} from "../source-dialog/source-dialog.component";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {SourceService} from "../../Services/source.service";
import {DataService} from "../../Services/data.service";
import {Business} from "../../../../../server/src/db/classes/business";
import ArrayHelpers from "../../../../../server/src/helpers/array-helpers";
import {MatSelectChange} from "@angular/material/select";
import {StringConstants} from "../../../../../server/src/helpers/string-constants";
import {ContactUsDialogComponent} from "../../marketing/pages/contact-us-dialog/contact-us-dialog.component";
import {NzDialogService} from "../../utils/services/nz-dialog.service";
import {LogoService} from "../../Services/logo-service";

@UntilDestroy()
@Component({
  selector: "app-yelp-invite-dialog",
  templateUrl: "./yelp-invite-dialog.component.html",
  styleUrls: ["./yelp-invite-dialog.component.scss"]
})
export class YelpInviteDialogComponent {
  link;
  whatsappLink;
  locationsNumber;
  moreOptions = false;

  selectedBusiness: Business | any;
  allBusinessesList$ = [];
  allBusinessesHashedById$ = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private SourceService: SourceService,
    public dataService: DataService,
    public logoService: LogoService,
    public nzDialogService: NzDialogService,
    public dialogRef: MatDialogRef<SourceDialogComponent>
  ) {
    // this.dialogRef.disableClose = this.dataService.allSources$.value.length===0;
    this.locationsNumber = data.locationsNumber;

    this.dataService.allBusinessesList$
      .pipe(untilDestroyed(this))
      .subscribe(allBusinessesList$ => {
        this.allBusinessesList$ = allBusinessesList$;
      });

    this.dataService.allBusinessesHashedById$
      .pipe(untilDestroyed(this))
      .subscribe(allBusinessesHashedById$ => {
        this.allBusinessesHashedById$ = allBusinessesHashedById$;
      });
    this.updateInviteLink();
  }

  updateInviteLink = async () => {
    const {state} = await this.SourceService.getInvite(this.selectedBusiness);

    const obj = {
      client_id: "4dRZWw9Gn4f-fO6v8ZIVLg",
      redirect_uri: "https://nzleads.com/api/accepted",
      response_type: "code",
      scope: "leads r2r_get_businesses r2r_business_owner",
      state
    };
    const url = new URL("https://biz.yelp.com/oauth2/authorize");

    Object.entries(obj).forEach(entry => {
      url.searchParams.append(...entry);
    });
    this.link = url.href;
  };

  filterOutAllBusiness = arr => {
    const newArr = arr
      .filter(x => x._id !== StringConstants.AllBusinessesId)
      .sort(ArrayHelpers.SortAlphabetically());
    if (!this.selectedBusiness && newArr.length > 0) {
      this.selectedBusiness = newArr[0]._id;
    }
    this.updateInviteLink();
    return newArr;
  };

  selectedBusinessChanged($event: MatSelectChange) {
    this.link = "";
    this.selectedBusiness = $event.value;
    this.updateInviteLink();
  }

  contact = () => {
    this.nzDialogService.open(ContactUsDialogComponent, {
      maxWidth: 600
    });
  };
}
