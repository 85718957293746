<h2 mat-dialog-title>Single Scheduled Message</h2>

<mat-dialog-content>
  <app-operation-config-follow-ups
    [inputConfig]="scheduledMessage"
    [inputGlobalConfig]="scheduledMessage"
    [isAllowedMultipleMessages]="false"
    [isGlobalConfig]="false">
  </app-operation-config-follow-ups>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button
    mat-raised-button
    color="primary"
    (click)="sendMessage()"
    [disabled]="this.scheduledMessage.predefinedMessages[0].text.length < 2 || isSending">
    Schedule
  </button>
</mat-dialog-actions>
